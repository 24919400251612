<template>
  <v-container fill-height class="space-between full-height">
    <v-row align="end" class="space-between">
      <v-card
        class="image-desc-container"
        v-for="(image, index) in pageData.Asset"
        :key="`imaget-${index}`"
      >
        <v-card-text class="text-content-style" v-html="image.Description">
        </v-card-text>
      </v-card>
      <v-card
        :class="{ NotClickable: !pageData.Asset[index].Clickable }"
        class="image-desc-container"
        v-for="(image, index) in pageData.Asset"
        :key="`image-${index}`"
        @click="openDialog(index)"
      >
        <v-img
          class="white--text align-end"
          :contain="getContain(image.CoverMode)"
          :height="getHeight(image.CoverMode)"
          :src="getImage(index)"
        >
        </v-img>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
//${this.assetsEndpoint}${this.data.Asset.formats.sm.url}
import SlideshowImage from "@/components/SlideshowImage";

import { mapState } from "vuex";
export default {
  components: {
    SlideshowImage,
  },
  computed: {
    ...mapState(["assetsEndpoint", "pageData", "slideshowAlignment"]),
  },
  methods: {
    getImage(index) {
      if (this.pageData !== null)
        return `${this.assetsEndpoint}${this.pageData.Asset[index].Asset.formats.md.url}?v=2.93`;
    },
    getContain(coverMode) {
      return coverMode === "contain" ? true : false;
    },
    getHeight(coverMode) {
      return coverMode === "contain" ? "450px" : "200px";
    },
    openDialog(index) {
      console.log(
        "this.pageData",
        this.pageData.Asset[index].Clickable,
        this.pageData.Asset[index]
      );
      if (this.pageData.Asset[index].Clickable === false) return;

      this.$store.commit("OPEN_DIALOG", {
        dialog: true,
        image: `${this.assetsEndpoint}${this.pageData.Asset[index].Asset.formats.md.url}`,
        ratio:
          this.pageData.Asset[index].Asset.formats.md.width /
          this.pageData.Asset[index].Asset.formats.md.height,
      });
    },
  },
};
</script>

<style lang="scss">
.align_center_left {
  justify-content: flex-start !important;
}

.align_center_right {
  justify-content: flex-end !important;
}

.align_center_center {
  justify-content: center !important;
}

.space-between {
  justify-content: space-around;
  // height: 50%;
  // padding-top: 25%;
}

.image-desc-container {
  // border: 1px solid green;
  max-width: 30%;
  min-width: 30%;
  width: 30% !important;
  box-shadow: unset !important;
  border-width: 0px !important;
  background: transparent !important;

  .v-card__text {
    padding: 0;
    padding-bottom: 4%;
  }

  b {
    display: block;
    padding-bottom: 4%;
  }
  border: 1px solid pink;
}

.ar {
  .text-content-style {
    direction: rtl;
  }
}

.NotClickable {
  cursor: unset !important;
}
</style>
