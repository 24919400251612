<template>
  <v-container class="form-container" fluid>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- <pre>{{ localeData.data.guestbook }}</pre> -->
      <v-layout row wrap class="form-width guest-root">
        <v-flex xs12 md3 class="box header-panel hp-guest bg-white">
          <p class="text-sub">{{ localeData.data.guestbook.Title }}</p>
          <p class="text-header">{{ localeData.data.guestbook.Header }}</p>
        </v-flex>

        <v-flex xs12 md9 class="box header-panel bg-white">
          <p></p>
        </v-flex>

        <v-flex xs12 md3 class="box left-panel bg-white">
          <p
            class="text-sub text-desc"
            v-html="localeData.data.guestbook.Description"
          ></p>
        </v-flex>
        <v-flex xs12 md5 class="box middle-panel bg-white">
          <v-text-field
            v-model="Firstname"
            :rules="nameRules"
            :placeholder="localeData.data.guestbook.Firstname"
            solo
            dense
            flat
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="Lastname"
            :rules="nameRules"
            :placeholder="localeData.data.guestbook.Lastname"
            solo
            dense
            flat
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="Company"
            :rules="nameRules"
            :placeholder="localeData.data.guestbook.Organization"
            solo
            dense
            flat
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="Email"
            :rules="emailRules"
            :placeholder="localeData.data.guestbook.Email"
            solo
            dense
            flat
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="NationalitySelected"
            :rules="nameRules"
            :placeholder="localeData.data.guestbook.Country"
            solo
            dense
            flat
            outlined
            required
          ></v-text-field>

          <v-textarea
            v-model="Comments"
            :rules="nameRules"
            :placeholder="localeData.data.guestbook.YourMessage"
            :counter="400"
            outlined
            required
          >
          </v-textarea>
        </v-flex>
        <v-flex xs12 md4 class="box right-panel bg-white">
          <v-btn
            color=""
            :class="{ disabled: formsending || formsent }"
            class="text-submitbtn"
            outlined
            x-large
            @click="submit"
            >{{ localeData.data.guestbook.Submit }}</v-btn
          >
        </v-flex>

        <v-flex xs3> </v-flex>
        <v-flex xs9>
          <p
            :class="{ active: formsent }"
            class="form-message bg-white mb-5"
            v-html="formReturnMessage"
          ></p>
        </v-flex>

        <!-- <v-flex xs12 class="wipo-logo-footer">
          <p
            :class="{ active: formsent }"
            class="form-message bg-white mb-5"
            v-html="formReturnMessage"
          ></p>
        </v-flex> -->
      </v-layout>
    </v-form>

    <!-- <v-row class="mt-6" no-gutters>
          <p class="mb-5 info-text"></p>
        </v-row>
      

      <v-col class="mb-5" cols="12 tester" xs="12" sm="12" md="1" lg="2" xl="4">
        &nbsp;
      </v-col> -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["localeData"]),
  },
  data: () => ({
    form_resp_timeout: null,
    form_action: process.env.VUE_APP_FORM2_ENDPOINT,
    companyLength: 30,
    formsending: false,
    formsent: false,
    formReturnMessage: "",
    valid: true,
    Firstname: "",
    Lastname: "",
    Email: "",
    NationalitySelected: "",
    Nationality: "", //contries,
    Company: "",
    Comments: "",

    // Firstname: 'bo',
    // Lastname: 'stilling',
    // Email: 'bostilling@gmail.com',
    // NationalitySelected: 0,
    // Nationality: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    // Company: 'BUT',
    // Comments: 'asddasdasdas asdassd',

    checkbox: false,
    commentRules: [
      (v) => !!v || "Comment is required",
      (v) =>
        (v && v.length <= 500) || "Comment must be less than 500 characters",
    ],
    nameRules: [
      (v) => !!v || "",
      // v => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    emailRules: [
      (v) => !!v || "",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  methods: {
    populate(e) {
      const date = Date.now().toString();

      this.Firstname = `firstname-${date}`;
      this.Lastname = `lastname-${date}`;
      this.Email = `${date}@email.com`;
      this.NationalitySelected = "Denmark";

      this.Company = "my Organization";
      this.Comments = "my Comments";
    },
    validate(e) {
      this.$refs.form.validate();
    },
    reset(e) {
      this.$refs.form.reset();
    },
    resetValidation(e) {
      this.$refs.form.resetValidation();
    },
    showResponceMessage(str) {
      //reset timeout
      if (this.form_resp_timeout !== null) {
        clearTimeout(this.form_resp_timeout);
        this.form_resp_timeout = null;
      }

      this.formReturnMessage = str;
      this.form_resp_timeout = setTimeout(() => {
        this.formsent = false;
      }, 8000);
    },
    async sendFormData() {
      const _self = this;

      const formData = {
        Firstname: this.Firstname,
        Lastname: this.Lastname,
        Email: this.Email,
        Nationality: this.NationalitySelected,
        Company: this.Company,
        Comments: this.Comments,
      };

      const options = {
        url: this.form_action,
        method: "POST",
        mode: "no-cors",
        // headers: {
        //   'Content-Type': 'application/json'
        // },
        data: JSON.stringify(formData),
      };

      console.log(process.env, options);

      try {
        // fetch data from a url endpoint
        const response = await _self.$axios(options);
        _self.formsent = true;
        _self.reset();

        const msg =
          response.statusText === "OK"
            ? _self.localeData.data.guestbook.ThankMessage
            : _self.localeData.data.guestbook.ErrorMessage;

        _self.showResponceMessage(msg);
      } catch (error) {
        _self.formsent = true;
        _self.showResponceMessage(_self.localeData.data.guestbook.ErrorMessage);
        console.warn(error);
      }

      this.formsending = false;

      // this.$axios(options)
      //   .then(function(response) {
      //     // console.log('success:', response)
      //     _self.formsent = true
      //     _self.formReturnMessage = 'Thank you for your input'
      //     _self.reset()
      //   })
      //   .catch(function(error) {
      //     // console.log('error:', error)
      //     _self.formsent = true
      //     _self.formReturnMessage = error
      //   })

      // return false

      // ** fetch
      // const options = {
      //   method: 'POST',
      //   mode: 'no-cors',
      //   body: JSON.stringify(formData)
      // }
      // fetch(FORM_ACTION_URL, options)
      //   .then(response => console.log('Success!', response))
      //   .catch(error => console.error('Error!', error.message))

      //'Content-Type': 'multipart/form-data'
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      // Accept: 'application/json',
      // 'Content-Type': 'application/json;charset=UTF-8',
      //  mode: 'no-cors',
      //  withCredentials: false,
    },
    submit() {
      if (this.formsent) return;

      this.formReturnMessage = "";

      if (this.$refs.form.validate()) {
        // console.log('validated!')
        this.formsending = true;
        this.sendFormData();
      } else {
        // console.log('errors!')
      }

      // this.$v.$touch()
      // if (this.$v.$invalid) {
      //   this.submitStatus = 'ERROR'
      // } else {
      //   // do your submit logic here
      //   this.submitStatus = 'PENDING'
      //   setTimeout(() => {
      //     this.submitStatus = 'OK'
      //   }, 500)
      // }
    },
  },
  // mounted() {
  //   console.log('sendto:', process.env)
  // }
};
</script>

<style lang="scss" scoped>
.guest-root {
  .form-cont {
    padding-left: 1% !important;
    max-width: 36.5% !important;
  }

  // @import url('https://fonts.googleapis.com/css?family=Questrial');
  // @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
  // // font-family: 'Questrial';
  // .v-application {
  //   font-family: 'Inter', sans-serif;
  // }

  .wipo-logo {
    width: 100%;
    padding: 2% 22% 0 0;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: rgba($color: #677c8f, $alpha: 0.05) !important;
  }

  h2 {
    text-align: left;
  }

  .v-btn,
  .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    border-radius: unset !important;
  }

  .info-text {
    font-size: 94.5%;
  }

  .text-desc {
    white-space: break-spaces;
  }

  .text-comment {
    // line-height: $stdTextSizeLh;
    padding: 0;
    // font-size: $stdTextSize;
  }

  // .text-submitbtn {
  //   height: 78px !important;
  //   margin-top: -163px;
  //   margin-left: 691px;
  //   span {
  //     font-size: 3.2vh;
  //     font-weight: 700;
  //     text-align: left;
  //     padding-right: 100px;
  //     text-transform: capitalize;
  //   }
  // }

  .half-width {
    width: 50%;
  }

  @media only screen and (max-width: 960px) {
    .text-header {
      padding: 0%;
    }
    .text-sub {
      padding: 0%;
      padding-bottom: 2%;
    }

    .half-width {
      width: 100%;
    }
  }

  .header-panel {
    padding-top: 5%;
  }

  .right-panel {
    display: flex;
    flex-flow: wrap-reverse;
    padding-bottom: 8px;
  }
}
.box {
  // border: 1px solid red;
}
</style>
