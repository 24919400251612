<template>
  <v-container class="full-height grid">
    <v-row align="center" justify="center" class="content-adjust">
      <v-col cols="8" class="text-content col-base">
        <vue-scroll :ops="ops" class="text-col ma-0 pl-2">
          <div class="pr-8" v-html="pageData.Description"></div>
        </vue-scroll>
      </v-col>
      <v-col cols="4" class="col-base right-pane img-col ma-0 pa-0 pl-11">
        <RightPanelPictureList :imageList="pageData.Asset" :class="getClass" />
      </v-col>
      <!-- <div class="col-left"></div>
      <div class="col-right"></div> -->
    </v-row>

    <!-- <v-row align="center" justify="center" class="full-h">
      <div class="items"></div>
      <div class="items"></div>
      <div class="items"></div>
    </v-row> -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import RightPanelPictureList from "@/components/RightPanelPictureList";

export default {
  components: {
    RightPanelPictureList,
  },

  data() {
    return {
      ops: {
        vuescroll: { mode: "native" },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          easing: "easeOutQuart",
          verticalNativeBarPos: "right",
          opacity: 1,
          keepShow: true,
        },
        rail: {
          keepShow: true,
        },
        bar: {
          background: "#858585",
          // onlyShowBarOnScroll: false,
          keepShow: true,
          hoverStyle: true,
        },
      },
    };
  },

  computed: {
    ...mapState(["pageData"]),
    getClass() {
      return `list-${this.pageData.Asset.length}`;
    },
    // getText() {
    //   return this.pageData.Description.replace(/\s/g, "&nbsp;");
    // },
  },
};
</script>

<style lang="scss">
.items {
  background: #858585;
  background: gray;
  width: 100%;
  height: 50px;
}

.col-base {
  // border: 1px solid red;
  height: calc(var(--bgscale) * 740);
  box-sizing: border-box;
}

.col-left {
  flex: 0 0 68%;
  max-width: 68%;
}

.col-right {
  flex: 0 0 32%;
  max-width: 32%;
}

.content-adjust {
  height: 100vh;
  padding-top: 58px;
}

.mobile .content-adjust {
  height: 100%;
}

.popup-content {
  width: 100%;
  padding: 2%;
}

.x-close-btn {
  font-size: 36px;
  position: absolute !important;

  top: 30px !important;
  right: 30px !important;
  background: black !important;
  border: 1px solid white;
  width: 36px;
  height: 36px;
  background: black;
  cursor: pointer;
  border-radius: unset !important;
}

.ar {
  .pr-8 {
    direction: rtl;
  }

  .content-adjust {
    // flex-direction: row-reverse;
    //transform: translateX(-8.5%);
  }
}
</style>
