<template>
  <v-container class="">
    <v-row align="center" justify="center" class="content-adjust full-height">
      <vueVimeoPlayer
        class="embed-container"
        v-show="showVideo"
        ref="player"
        :aspect-ratio="640 / 270"
        :autoplay="true"
        :video-id="pageData.VideoUrl"
        @play="onPlay"
        @ready="onReady"
        :options="getPlayerOptions"
      ></vueVimeoPlayer>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  components: {
    vueVimeoPlayer,
  },
  data() {
    return {
      player: null,
      showVideo: false,
      playerOptions: {
        // controls: true,
        // color: "#ef00c7",
      },
      // height: 500,
    };
  },
  computed: {
    ...mapState(["pageData", "isMobile"]),
    getPlayerOptions() {
      console.log("this.isMobile:", this.isMobile);
      return {
        muted: this.isMobile,
      };
    },
  },
  methods: {
    setCaption(langcode) {
      this.player
        .enableTextTrack(langcode)
        .then(function(track) {
          // track.language = the iso code for the language
          // track.kind = 'captions' or 'subtitles'
          // track.label = the human-readable label
        })
        .catch(function(error) {
          switch (error.name) {
            case "InvalidTrackLanguageError":
              // no track was available with the specified language
              break;

            case "InvalidTrackError":
              // no track was available with the specified language and kind
              break;

            default:
              // some other error occurred
              break;
          }
        });
    },
    onPlay(player) {
      // console.log("onPlay", player);
    },

    onReady(player) {
      // console.log("onReady", this.pageData.VideoUrl, player);
      // console.log(player);

      this.showVideo = true;

      // player
      //   .setColor("#ef00c7")
      //   .then(function (color) {
      //     // the color that was set
      //   })
      //   .catch(function (error) {
      //     // an error occurred setting the color
      //   });

      this.player = player;
      this.player
        .getTextTracks()
        .then(function(tracks) {
          // tracks = an array of track objects
          tracks.forEach((track) => {
            console.table(track);
          });
        })
        .catch(function(error) {
          // an error occurred
        });

      this.player.on("ended", this.onEnded);
      console.log(this.$i18n.locale, this.$i18n);
      this.setCaption(this.$i18n.locale);
    },
    onEnded(args) {
      console.log("onEnded");
      // console.table(args);
    },
  },
  mounted() {
    // this.$refs.player.update(this.videoId);
  },
  destroyed() {
    console.log("destroyed");
    if (this.player !== null) this.player.off("ended", this.onEnded);
  },
};
</script>

<style lang="scss">
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ef00c7;
}

iframe .player .vp-controls .logo {
  display: none;
}
</style>
