
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import * as en from './locales/en.json'
import * as fr from './locales/fr.json'
import * as es from './locales/es.json'
import * as ar from './locales/ar.json'
import * as ch from './locales/ch.json'
import * as ru from './locales/ru.json'


Vue.use(VueI18n)

const messages = {
    "en": {
        ...en
    },
    "fr": {
        ...fr
    },
    "es": {
        ...es
    },
    "ar": {
        ...ar
    },
    "ch": {
        ...ch
    },
    "ru": {
        ...ru
    }
}

console.log(messages);


export default new VueI18n({
    locale: 'en',
    messages,
})