import Vue from "vue";
import App from "./App.vue";
// import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import axios from 'axios'
Vue.prototype.$axios = axios

import i18n from "./i18n";

import vuescroll from "vuescroll/dist/vuescroll-native";
// import the css file
import "vuescroll/dist/vuescroll.css";
Vue.use(vuescroll);

Vue.config.productionTip = false;

new Vue({
  // router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
