<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="2250"
    height="1080"
    viewBox="0 0 2250 1080"
    preserveAspectRatio="xMidYMid  slice"
  >
    <defs>
      <!-- <clipPath id="clip-main-bg">
        <rect width="2250" height="1080" />
      </clipPath> -->
    </defs>
    <image
      v-if="locale != 'ar'"
      id="countries-page-bg"
      width="2250"
      height="1080"
      xlink:href="~@/assets/countries-page-bg.png"
    />

    <image
      v-if="locale === 'ar'"
      id="countries-page-bg"
      width="2250"
      height="1080"
      xlink:href="~@/assets/countries-page-bg-ar.png"
    />
    <image
      v-show="ArrowPrev"
      @click="prev"
      id="arrow_l_180"
      width="180"
      height="180"
      transform="translate(267 492)"
      xlink:href="~@/assets/arrow_l_180.png"
    />
    <image
      v-show="ArrowNext"
      @click="next"
      id="arrow_r_180"
      width="180"
      height="180"
      transform="translate(1801 492)"
      xlink:href="~@/assets/arrow_r_180.png"
    />
  </svg>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["ArrowPrev", "ArrowNext", "locale"]),
  },
  methods: {
    next() {
      this.$store.dispatch("next_page");
    },
    prev() {
      this.$store.dispatch("prev_page");
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  pointer-events: none;
}

.ar {
  svg {
    transform: scale(-1, 1);
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  #arrow_l_180 {
    transform: translate(382px, 492px);
  }

  #arrow_r_180 {
    transform: translate(1688px, 492px);
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  #arrow_l_180 {
    transform: translate(382px, 492px);
  }

  #arrow_r_180 {
    transform: translate(1688px, 492px);
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  #arrow_l_180 {
    transform: translate(382px, 492px);
  }

  #arrow_r_180 {
    transform: translate(1688px, 492px);
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  #arrow_l_180 {
    transform: translate(382px, 492px);
  }

  #arrow_r_180 {
    transform: translate(1688px, 492px);
  }
}

/* ----------- iPhone X ----------- */

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  #arrow_l_180 {
    transform: translate(267px, 492px);
  }

  #arrow_r_180 {
    transform: translate(1801px, 492px);
  }
}

#arrow_l_180,
#arrow_r_180 {
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
  display: block;
  //   &:hover {
  //     opacity: 0.9;
  //   }
}
</style>
