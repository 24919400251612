<template>
  <v-container fluid class="countries-bg " v-cloak id="page">
    <v-row align="center" justify="center" class="full-height">
      <div class="countries-list">
        <!-- :transition="200" -->
        <hooper
          ref="carousel"
          :initialSlide="countryIndex"
          :vertical="true"
          class="scroll-container noselect"
          :itemsToShow="getItems"
          :centerMode="true"
          :wheelControl="true"
          :keysControl="true"
          :shortDrag="false"
        >
          <slide v-for="(c, index) in countries" :key="`c-${index}`">
            <div
              @click="countryClicked(index)"
              v-html="$t(`default.${c}`)"
            ></div>
          </slide>
        </hooper>
        <div class="masker"></div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "HelloWorld",
  components: {
    Hooper,
    Slide,
  },

  data: () => ({
    showlist: false,
  }),
  watch: {
    countryIndex() {
      this.$refs.carousel.slideTo(this.countryIndex);
      console.log(this.countries[this.countryIndex]);
    },
  },

  computed: {
    ...mapState(["countryIndex", "countries", "isMobile"]),
    getItems() {
      return this.isMobile ? 7 : 13;
    },
  },
  methods: {
    countryClicked(_countryIndex) {
      if (this.countryIndex === _countryIndex) {
        this.$store.dispatch("set_country", this.countryIndex);
      }
      this.$store.commit("SET_COUNTRY_INDEX", _countryIndex);
    },
  },
  mounted() {
    console.log(this.$i18n);
    this.$store.commit("SET_PAGE", null);
  },
};
</script>

<style lang="scss">
.container.countries-bg {
  width: 100%;
  height: 100%;

  background: url(~@/assets/countries-selector.png) no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  // background: url(../assets/countries-page-bg.png) no-repeat center center fixed;
  // background: url(../assets/countries-page-bg-m.png) no-repeat center center fixed;
  // background-size: cover;
  // max-width: unset;
  // min-width: 1024px !important;
}

.ar {
  .container.countries-bg {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
}

.countries-list {
  pointer-events: none;
  width: calc(var(--bgscale) * 679);
  height: calc(var(--bgscale) * 679);
  // min-width: var(--bgscale);
  position: relative;

  display: flex;
  // flex-wrap: wrap;
  // flex: 1 1 auto;
  justify-content: center !important;
  align-items: center !important;

  // background: red;
  // margin: -18px 0 0 220px;

  text-transform: uppercase !important;
  // font-family: Arial, Helvetica, sans-serif;
  font-size: calc(var(--bgscale) * 16);
  line-height: calc(var(--bgscale) * 16);
  font-weight: bold;
  .hooper-slide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    div {
      display: inline-block;
      // white-space: nowrap;
      // overflow: hidden;
      text-overflow: ellipsis;
      // margin-top: 1%;
    }
    color: #808080;
    transition: all 0.3s;
    &.is-current {
      color: #080809;
      font-size: calc(var(--bgscale) * 20);
      line-height: calc(var(--bgscale) * 20);

      height: calc(var(--bgscale) * 30);

      // font-weight: normal;
      // margin-bottom: 1.2em !important;
      margin-left: calc(var(--bgscale) * -8);
      // border: 1px solid purple;
    }
  }
}

.ar {
  .hooper-slide {
    direction: rtl;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }
}

.scroll-container {
  pointer-events: all;
  margin-left: calc(var(--bgscale) * -75);
  width: calc(var(--bgscale) * 349);
  height: calc(var(--bgscale) * 566);
  // border: 1px solid;
}

.masker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(~@/assets/countries-selector-mask.png) no-repeat center center;
  background-size: cover;
  pointer-events: none;
}

.mobile {
  .countries-list {
    width: calc(var(--bgscale) * 1019);
    height: calc(var(--bgscale) * 1019);
  }

  .container.countries-bg {
    background: url(~@/assets/countries-selector-mobile.png) no-repeat center
      center fixed;
    background-size: cover;
  }

  .masker {
    background: url(~@/assets/countries-selector-mask-mobile1.png) no-repeat
      center center fixed;
    background-size: cover;
  }

  .scroll-container {
    margin-left: calc(var(--bgscale) * -95);
    width: calc(var(--bgscale) * 490);
    height: calc(var(--bgscale) * 850);
  }

  .countries-list {
    font-size: calc(var(--bgscale) * 24);
    line-height: calc(var(--bgscale) * 24);

    .hooper-slide {
      margin-left: calc(var(--bgscale) * -18);
      &.is-current {
        font-size: calc(var(--bgscale) * 30);
        line-height: calc(var(--bgscale) * 30);
        // border: 5px solid cyan;

        margin-left: calc(var(--bgscale) * -24);
      }
    }
  }
}
</style>
