<template>
  <v-app
    :class="getRootClass"
    class="main-bg-color "
    v-show="currentView !== null"
  >
    <v-main>
      <transition name="fade" mode="out-in">
        <component :is="currentView"></component>
      </transition>
    </v-main>
    <div id="close-btn" @click="close()"></div>

    <div class="preload">
      <img
        v-for="(img, index) in preloadList"
        :key="`image-${index}`"
        alt=""
        :src="img"
      />
    </div>
    <v-dialog v-model="dialogShow" overlay width="90%">
      <v-card elevation="0">
        <v-img
          class="popup-content"
          contain
          :aspect-ratio="dialogContentRatio"
          :src="dialogContent"
        ></v-img>

        <v-icon dark right large class="x-close-btn" @click="closeDialog()">
          mdi-close
        </v-icon>
        <!-- <img :src="dialogContent" alt=""  /> -->
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import CountryList from "@/views/CountryList";
import CountryPage from "@/views/CountryPage";
import FeedbackForm from "@/views/FeedbackForm";
import GuestbookForm from "@/views/GuestbookForm";

export default {
  name: "App",
  components: {
    CountryList,
    CountryPage,
    FeedbackForm,
    GuestbookForm,
  },

  data: () => ({
    preloadList: [
      "img/arrow_l_180.png",
      "img/arrow_r_180.png",

      "img/countries-page-bg.png",
      "img/countries-page-bg-ar.png",
      "img/countries-selector-mask.png",
      "img/countries-selector.png",
    ],
    //
  }),
  computed: {
    ...mapState([
      "currentView",
      "dialogContent",
      "dialogContentRatio",
      "isMobile",
      "locale",
    ]),
    dialogShow: {
      get() {
        return this.$store.state.dialogShow;
      },
      set(value) {
        this.$store.commit("OPEN_DIALOG", {
          dialog: false,
          image: null,
        });
      },
    },
    getRootClass() {
      const m = this.isMobile ? "mobile" : "";
      return `${m} ${this.locale}`;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("OPEN_DIALOG", {
        dialog: false,
        // image: null,
      });
    },
    // getDialogHeight() {
    //   return "80vh";
    // },
    onResize() {
      const body = document.body;
      const html = document.documentElement;

      this.windowWidth = document.documentElement.clientWidth;
      this.windowWidth = document.documentElement.clientHeight;
    },
    close() {
      switch (this.currentView) {
        case "CountryList":
        case "GuestbookForm":
        case "FeedbackForm":
          if (process.env.NODE_ENV === "development") {
            // console.log("not closing in development");
            return;
          }
          this.disabletouchOnVista(false);
          this.$store.commit("SET_VIEW", null);
          break;

        case "CountryPage":
          this.$store.commit("SET_VIEW", "CountryList");
          break;
      }
    },
    disabletouchOnVista(disable) {
      // if (!this.isMobile) return;
      // const pEvent = disable ? "none" : "all";
      // document.querySelector("#viewer").style.pointerEvents = pEvent;
    },
    onVistaEvent(e) {
      // console.log(`[VUE] type:${e.detail.type} param:${e.detail.param}`);
      switch (e.detail.type) {
        case "locale":
          this.$i18n.locale = e.detail.param;
          this.$store.dispatch("set_locale", e.detail.param);

          this.langclass = e.detail.param;

          if (this.checkMobile()) {
            this.$store.commit("SET_MOBILE", true);

            if (!document.fullscreenElement) {
              //document.documentElement.requestFullscreen();
            }

            if (document.webkitFullscreenElement) {
              document.webkitCancelFullScreen();
            } else {
              const el = document.documentElement;
              el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
          }

          break;

        case "country":
          this.disabletouchOnVista(true);
          this.$store.commit("SET_VIEW", "CountryList");
          break;

        case "guestbook":
          this.disabletouchOnVista(true);
          this.$store.commit("SET_VIEW", "GuestbookForm");
          break;

        case "feedback":
          this.disabletouchOnVista(true);
          this.$store.commit("SET_VIEW", "FeedbackForm");
          break;

        default:
          break;
      }
    },
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    window.addEventListener("CountrySelectorEvent", this.onVistaEvent);
    window.addEventListener("SetLocalEvent", this.onVistaEvent);
    window.addEventListener("FeedbackEvent", this.onVistaEvent);
    window.addEventListener("GuestbookEvent", this.onVistaEvent);

    this.$store.dispatch("download_locales");

    this.$nextTick(function() {
      if (this.checkMobile()) this.$store.commit("SET_MOBILE", true);
      //window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("CountrySelectorEvent", this.onVistaEvent);
    window.removeEventListener("SetLocalEvent", this.onVistaEvent);
    window.removeEventListener("FeedbackEvent", this.onVistaEvent);
    window.removeEventListener("GuestbookEvent", this.onVistaEvent);

    //window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

:root {
  /* background image size (source) */

  --bgw: 2160;
  --bgh: 1080;

  /* projected background image size and position */

  --bgscale: max(calc(100vh / var(--bgh)), calc(100vw / var(--bgw)));

  --pbgw: calc(var(--bgw) * var(--bgscale)); /* projected width */
  --pbgh: calc(var(--bgh) * var(--bgscale)); /* projected height */

  --bgLeftOverflow: calc((var(--pbgw) - 100vw) / 2);
  --bgTopOverflow: calc((var(--pbgh) - 100vh) / 2);
}

html,
body {
  overflow: hidden !important;
  padding: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  // min-height: 100%;
  // min-width: 100%;
  // border: 2px solid purple;

  overflow-x: hidden;
}
// html,
// body {
//   overflow: hidden !important;
//   padding: 0 !important;
//   width: 100vw !important;
//   height: 100vh !important;
// }

// #app.mobile {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   overflow: auto;
// }

.theme--light.v-application {
  background: transparent !important;
  // background: rgb(30, 30, 30) !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
  outline: none;
}

#close-btn {
  position: absolute;
  width: calc(var(--bgscale) * 50);
  height: calc(var(--bgscale) * 50);
  min-width: 30px;
  min-height: 30px;

  right: 2.58%;
  top: 1.72%;
  background: url(~@/assets/close-btn.png) no-repeat center center;
  background-size: cover;
  cursor: pointer;
}

#app {
  z-index: 2;
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  box-sizing: border-box !important;
  min-width: 0px !important;
  max-width: none !important;
  min-height: 0px !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
  margin: 0px !important;
}

.preload {
  display: none;
  pointer-events: none;
}

.full-height {
  height: 100vh;
  // margin-top: -56px;
}

.full-height-percent {
  height: 100%;
}

b,
strong {
  font-weight: bold !important;
}

.v-application {
  p {
    margin: 0 !important;
  }

  .ql-align-justify {
    span {
      font-size: calc(var(--bgscale) * 27);
      line-height: calc(var(--bgscale) * 36);
    }
  }

  .text-content {
    // font-family: Arial, Helvetica, sans-serif;

    overflow: hidden;
    padding: 0 2% 0 2% !important;
    // overflow-y: scroll; /* has to be scroll, not auto */
    // -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */

    font-size: calc(var(--bgscale) * 27);
    line-height: calc(var(--bgscale) * 31);

    h2 {
      font-weight: bold !important;
      strong {
        font-weight: bold !important;
      }
      font-size: calc(var(--bgscale) * 36);
      line-height: calc(var(--bgscale) * 38);
      margin-bottom: calc(var(--bgscale) * 26);
    }

    ul {
      list-style: none;
      margin-left: 2%;
      padding-left: 0.5em;
    }
    ul > li:before {
      display: inline-block;
      content: "-";
      width: 0.5em;
      margin-left: -0.5em;
    }
  }

  .text-content-style {
    // font-family: Arial, Helvetica, sans-serif;

    // overflow-y: scroll; /* has to be scroll, not auto */
    // -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */

    font-size: calc(var(--bgscale) * 27);
    line-height: calc(var(--bgscale) * 31);
    color: black !important;
  }
}

// *::-webkit-scrollbar {
//   width: 6px;
// }

// /* Track */
// *::-webkit-scrollbar-track {
//   background: transparent;
// }

// /* Handle */
// *::-webkit-scrollbar-thumb {
//   background: rgba($color: #000000, $alpha: 0.3);
// }

// /* Handle on hover */
// *::-webkit-scrollbar-thumb:hover {
//   background: rgba($color: #000000, $alpha: 0.3);
// }

.content-max-height {
  height: calc(var(--bgscale) * 740);
}

@import url("../public/fonts.css");

.v-application {
  font-family: Arial, Helvetica, sans-serif !important;
}
.v-application.ar {
  font-family: "Cairo", sans-serif !important;
}
.v-application.ch {
  font-family: "Noto Sans TC", sans-serif !important;
}

.v-dialog {
  max-width: 1500px !important;
  // min-height: 80vh !important;
  .v-card {
    background: #ffffff !important;
  }
}

//forms

.hp-guest {
  padding: 5% 2% 0% 2%;
}

.left-panel {
  padding: 0 2%;
}

.form-container {
  background-image: url("~@/assets/g-bg2-en.png");

  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  // background-image: url("~@/assets/guide-guest.png");
  // background-color: white;
  height: 100vh;
  overflow: auto;

  .bg-white {
    background: #fafafa;
  }

  .v-btn {
    margin-left: 8%;
    background-color: black !important;
    color: white !important;
    min-width: 35% !important;
    min-height: 5vh !important;
    opacity: 1;
    transition: opacity 0.3s;
    font-size: 28px;
    &.disabled {
      opacity: 0.2;
    }
  }

  .text-header {
    padding: 0 8% 4% 0%;
    font-size: 36px;
    font-size: 40px;
    max-width: 100%;
    font-weight: 600;
  }

  .text-sub {
    padding: 0 8% 0 0%;
  }

  .text-submitbtn {
    text-transform: capitalize;
  }

  .form-message {
    font-size: 16px;
    opacity: 0;
    padding: 1% 0;
    transition: all 0.4s;
    min-height: 92px;
    white-space: pre;
    &.active {
      opacity: 1;
      margin-top: 0%;
    }
  }

  .v-input__control,
  .v-input__slot {
    background: #fafafa !important;
  }

  // .v-input__control {
  //   // height: 64px;
  //   .v-input__slot {`
  //     min-height: 40px;
  //     input {
  //       padding: 0;
  //     }
  //   }
  // }

  .v-input__control {
    background-color: #fafafa !important;
  }
  .v-textarea {
    .v-input__control {
      height: 128px;
    }
  }
}

// /* ----------- iPad  ----------- */
// /* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .form-container {
    background-size: contain;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .form-container {
    background-size: contain;
  }
}
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .form-container {
    background-size: contain;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .form-container {
    background-size: contain;
  }
}

.ar {
  .form-container {
    direction: rtl;
    .v-btn {
      margin-right: 8%;
    }

    .text-header {
      padding-right: 0;
    }

    .text-sub {
      padding-right: 0;
    }

    .left-panel {
      padding: 0 2%;
    }

    .wipo-logo-footer {
      background-image: url("~@/assets/g-bg2-ar.png");
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
    }
  }
}

.bar-footer {
  width: 100%;
  height: 40.5vh;
  object-fit: cover;
  background: white;
}

.wipo-logo-footer {
  background-image: url("~@/assets/g-bg1-en.png");
  height: 40.5vh;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

.mobile {
  .form-container {
    .v-btn {
      margin-right: 0%;
      margin-left: 0%;
    }
  }

  .middle-panel {
    padding-top: 4% !important;
  }

  .bg-white {
    padding: 0 2%;
  }

  .ar {
    .form-container {
      .v-btn {
        margin-right: 0%;
        margin-left: 0%;
      }
    }

    .middle-panel {
      padding-top: 4% !important;
    }

    .bg-white {
      padding: 0 2%;
    }
  }
}
</style>
