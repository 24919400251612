<template>
  <v-responsive
    :class="getClass"
    :style="
      `background: url(${getImage}) no-repeat center center;background-size: cover;`
    "
    @click="openDialog()"
  >
  </v-responsive>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["assetsEndpoint"]),
    getImage() {
      if (this.data !== null)
        return `${this.assetsEndpoint}${this.data.Asset.formats.sm.url}?v1.6`;
    },
    getClass() {
      // console.log(this.data.Asset);
      let clickable = this.data.Clickable ? "clickable" : "";

      let coverMode = this.data.CoverMode == "contain" ? "contain" : "cover";

      let alignment = "cc";

      switch (this.data.Alignment) {
        case "align_top_left":
          alignment = "tl";
          break;
      }

      return `${this.data.Size} ${clickable} ${coverMode} ${alignment}`;
    },
  },
  methods: {
    openDialog() {
      if (this.data.Clickable === false) return;

      this.$store.commit("OPEN_DIALOG", {
        dialog: true,
        image: `${this.assetsEndpoint}${this.data.Asset.formats.md.url}`,
      });
    },
  },
  mounted() {
    // console.log("data", this.data);
  },
};
</script>

<style lang="scss" scoped>
.Default {
  padding-top: 64.88%;
}

.Heightx2 {
  padding-top: 141.66%;
}

.Heightx3 {
  padding-top: 218%;
}

.clickable {
  cursor: pointer;
  background-size: contain !important;
  background-position-y: top !important;
}

.contain {
  background-size: contain !important;
}

.tl {
  background-position: top left !important;
}
</style>
