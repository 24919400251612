<template>
  <v-container class="page-root">
    <Mainbg />
    <v-row align="start" justify="center" class="full-height-percent">
      <div class="centered-content">
        <transition name="fade" mode="out-in">
          <component
            :is="getCurrentCountryPage"
            :key="forceRerender"
          ></component>
        </transition>
        <!-- <pre>|{{ this.bgvalues.bgscale }}|</pre> -->
        <!-- <div v-show="ArrowPrev" class="arrow-btn prev" @click="prev"></div>
        <div v-show="ArrowNext" class="arrow-btn next" @click="next"></div> -->
        <!-- <v-btn fab plain color="primary" x-large class="arrow-btn "></v-btn> -->
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import InfoPage from "@/components/InfoPage";
import SlideshowPage from "@/components/SlideshowPage";
import SlideshowPageDesc from "@/components/SlideshowPageDesc";

import VideoPage from "@/components/VideoPage";

import Mainbg from "@/components/Mainbg";

export default {
  components: {
    InfoPage,
    SlideshowPage,
    SlideshowPageDesc,
    VideoPage,
    Mainbg,
  },
  data: () => ({
    bgvalues: {
      bgw: "",
      bgh: "",
      bgscale: "",
      pbgw: "",
      pbgh: "",
      bgTopOverflow: "",
      bgLeftOverflow: "",
    },
    componentKey: 0,
  }),
  computed: {
    ...mapState([
      "currentCountryPage",
      "ArrowPrev",
      "ArrowNext",
      "forceRerender",
    ]),
    getCurrentCountryPage() {
      var p = null;
      console.log("[getCurrentCountryPage]", this.currentCountryPage);
      switch (this.currentCountryPage) {
        case "ComponentTextText":
          p = "InfoPage";
          break;
        case "ComponentVideoVideo":
          p = "VideoPage";
          break;
        case "ComponentSlideshowSlideshow":
          p = "SlideshowPage";
          break;

        case "ComponentSlideshowSlideshowWithDescription":
          p = "SlideshowPageDesc";
          break;
      }
      return p;
    },
  },
  methods: {
    next() {
      this.$store.dispatch("next_page");
    },
    prev() {
      this.$store.dispatch("prev_page");
    },
  },
  mounted() {
    const computedStyle = window.getComputedStyle(document.documentElement);
    // .getPropertyValue("--bgw");

    window.onresize = () => {
      const vw100 = window.innerWidth;
      const vh100 = window.innerHeight;

      /* background image size (source) */

      const bgw = 2160;
      const bgh = 1080;

      /* projected background image size and position */

      const bgscale = Math.max(vh100 / bgh, vw100 / bgw);

      const projectedWidth = (bgw * bgscale) | 0;
      const projectedHeight = (bgh * bgscale) | 0;

      const leftOverflow = ((projectedWidth - vw100) / 2) | 0;
      const topOverflow = ((projectedHeight - vh100) / 2) | 0;

      this.bgvalues.bgscale = bgscale.toFixed(2);

      // console.log(
      //   bgscale.toFixed(2),
      //   projectedWidth,
      //   projectedHeight,
      //   leftOverflow,
      //   topOverflow
      // );
    };
  },
};
</script>

<style lang="scss" scoped>
$base-color: #c6538c;
$xoffset: 265.5;

.page-root {
  padding: 0 !important;
  position: absolute;
  width: 100vw;
  height: 100vh;
  // max-width: 2300px !important;

  // background: url(~@/assets/countries-page-bg.png) no-repeat center center fixed;
  // background: url(../assets/countries-page-bg.png) no-repeat center center fixed;
  // background: url(../assets/countries-page-bg-m.png) no-repeat center center fixed;
  background-size: cover;
  max-width: unset;
  overflow: none;

  // min-width: 1024px !important;
}

// @media screen and (max-width: 2300px) {
//   .page-root {
//     background-size: cover;
//   }
// }

//- --bgTopOverflow
.centered-content {
  position: relative;
  // border: 2px solid magenta;
  width: calc(var(--bgscale) * 1148);
  // height: calc(var(--bgscale) * 738);
  // height: 100vh;
  display: block;
  // width: 50vw;

  object-fit: cover;

  // margin: auto;

  // margin-top: calc(var(--bgscale) * 60);
}

.mobile .centered-content {
  margin-top: 2%;
}

.arrow-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  width: calc(var(--bgscale) * 180);
  height: calc(var(--bgscale) * 180);
  background: url(~@/assets/arrow_r_180.png) no-repeat center center;
  background-size: contain;

  right: 0;
  transform: translate(calc(var(--bgscale) * 282), calc(var(--bgscale) * -48));
  &.prev {
    left: 0;
    background: url(~@/assets/arrow_l_180.png) no-repeat center center;
    background-size: contain;
    transform: translate(
      calc(var(--bgscale) * -282),
      calc(var(--bgscale) * -48)
    );

    filter: FlipH;
    -ms-filter: "FlipH";
  }

  &:hover {
  }
}

@media screen and (max-width: 1366px) {
  .page-root {
    .arrow-btn {
      transform: translate(
        calc(var(--bgscale) * 166),
        calc(var(--bgscale) * -48)
      );
      &.prev {
        transform: translate(
          calc(var(--bgscale) * -166),
          calc(var(--bgscale) * -48)
        );
      }
    }
  }
}

pre {
  text-align: center;
}
</style>
