<template>
  <!-- <div
    class="content-area-height"
    :style="`background: url(${getImage}) no-repeat
    center center;background-size: contain;`"
  ></div> -->
  <img :src="getImage" alt="" class="default-content-height" />
</template>

<script>
//:class="getClass"
import { mapState } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState(["assetsEndpoint"]),
    getImage() {
      if (this.data !== null)
        return `${this.assetsEndpoint}${this.data.Asset.formats.md.url}?v=2.95`;
    },
  },
  mounted() {
    console.log(this.data.Asset);
    this.$store.commit(
      "SET_SLIDESHOW_ALIGNMENT",
      `${this.data.Alignment} ${this.data.CoverMode}`
    );
  },
};
</script>

<style lang="scss" scoped>
// .content-area-height {
//   height: calc(var(--bgscale) * 740);

//   // display: flex;
//   // flex-direction: column;
//   // flex-wrap: nowrap;

//   // justify-content: center;
//   // align-content: flex-start;
// }
.default-content-height {
  height: calc(var(--bgscale) * 710);
}
</style>
