import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import en from "@/store/locales/en.json";
import fr from "@/store/locales/fr.json";
import es from "@/store/locales/es.json";
import ar from "@/store/locales/ar.json";
import ch from "@/store/locales/ch.json";
import ru from "@/store/locales/ru.json";

const locales = ["en", "fr", "es", "ar", "ar-AE", "zh", "ru"];

export default new Vuex.Store({
  state: {
    isMobile: false,
    slideshowAlignment: "",

    // assetsEndpoint: "https://wipodata.devae1.buthost.com",
    assetsEndpoint: "./img",
    dialogShow: false,
    dialogContent: null,
    dialogContentRatio: 1.77777777,

    forceRerender: 0,
    currentView: null, //CountryPage CountryList
    currentCountryPage: null, //InfoPage //SlideshowPage //VideoPage
    ArrowPrev: false,
    ArrowNext: false,
    locale: "en",
    localeData: null,

    countryData: [],

    pageIndex: 0,
    pageData: null,

    countryIndex: 0,
    countries: [
      "Algeria",
      "Argentina",
      "Brazil",
      "Cabo Verde",
      "Cambodia",
      "China",
      "Colombia",
      "Dominican Republic",
      "Ecuador",
      "El Salvador",
      "France",
      "Georgia",
      "Germany",
      "Hungary",
      "Indonesia",
      "Iran (Islamic Republic of)",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Mexico",
      "Montenegro",
      "Morocco",
      "North Macedonia",
      "Pakistan",
      "Peru",
      "Portugal",
      "Republic of Moldova",
      "Russian Federation",
      "Serbia",
      "Spain",
      "Switzerland",
      "Thailand",
      "Turkey",
      "United Kingdom",
      "United States of America",
      "Uzbekistan",
      "Venezuela (Bolivarian Republic of)",
      "Viet Nam",
      "African Intellectual Property Organization",
      "European Union",
    ],
  },
  mutations: {
    SET_MOBILE(state, payload) {
      Vue.set(state, "isMobile", payload);
    },
    SET_SLIDESHOW_ALIGNMENT(state, payload) {
      Vue.set(state, "slideshowAlignment", payload);
    },

    SET_COUNTRY_INDEX(state, payload) {
      Vue.set(state, "countryIndex", payload);
    },
    SET_PAGE(state, payload) {
      Vue.set(state, "currentCountryPage", payload);
    },
    SET_VIEW(state, payload) {
      Vue.set(state, "currentView", payload);
    },
    SET_LOCALE(state, payload) {
      console.log("[SET_LOCALE]", payload);
      Vue.set(state, "locale", payload);
    },
    SET_LOCALE_DATA(state, payload) {
      console.log("[SET_LOCALE_DATA]", payload);
      Vue.set(state, "localeData", payload);
    },
    SET_COUNTRY_DATA(state, payload) {
      console.log("[SET_COUNTRY_DATA]", payload);
      Vue.set(state, "countryData", payload);
    },

    SET_PAGE_INDEX(state, payload) {
      Vue.set(state, "pageIndex", payload);
      Vue.set(state, "pageData", state.countryData[payload]);
      let rerender =
        state.countryData[payload].Type === state.currentCountryPage
          ? true
          : false;

      Vue.set(state, "currentCountryPage", state.countryData[payload].Type);

      //rerender if same component
      if (rerender) {
        // console.log("rerender", rerender);
        Vue.set(state, "forceRerender", state.forceRerender + 1);
      }
    },

    UPDATE_ARROWS(state, payload) {
      state.ArrowPrev = payload.prev;
      state.ArrowNext = payload.next;
    },
    OPEN_DIALOG(state, payload) {
      state.dialogShow = payload.dialog;

      if (payload.dialog) state.dialogContent = payload.image;
    },
  },
  actions: {
    set_locale({ state, commit }, payload) {
      console.log("[set_locale]", payload);
      // change countryData

      switch (payload) {
        case "en":
          commit("SET_LOCALE_DATA", en);
          break;

        case "fr":
          commit("SET_LOCALE_DATA", fr);
          break;

        case "es":
          commit("SET_LOCALE_DATA", es);
          break;

        case "ar":
          commit("SET_LOCALE_DATA", ar);
          break;

        case "ch":
          commit("SET_LOCALE_DATA", ch);
          break;

        case "ru":
          commit("SET_LOCALE_DATA", ru);
          break;
      }
      commit("SET_LOCALE", payload);
    },
    set_country({ state, commit }, payload) {
      // find country in current localeData
      var c = state.localeData.data.countries.find(
        (x) => x.name === state.countries[payload]
      );

      // if found set country data
      if (c !== undefined) {
        commit("SET_COUNTRY_DATA", c.Info);

        // reset page index
        commit("SET_PAGE_INDEX", 0);

        console.log("[pageData]", state.pageData, state.pageData.Type);

        //reset arrows
        commit("UPDATE_ARROWS", {
          prev: false,
          next: state.countryData.length > 1 ? true : false,
        });

        commit("SET_VIEW", "CountryPage");
        commit("SET_PAGE", state.pageData.Type);
      } else {
        console.log("[Country Not Found]", state.countries[payload]);
      }
    },
    prev_page({ state, commit }) {
      var nextindex = state.pageIndex - 1;
      if (nextindex >= 0) {
        commit("SET_PAGE_INDEX", nextindex);
      }

      var arrows = {
        prev: state.pageIndex >= 1 ? true : false,
        next: state.pageIndex < state.countryData.length ? true : false,
      };
      commit("UPDATE_ARROWS", arrows);

      console.log("[*****************]");
      console.log(
        "[next_page]",
        state.pageIndex,
        "#",
        state.countryData.length
      );
      console.log("[next_page type]", state.pageData.Type, state.pageData);
    },
    next_page({ state, commit }) {
      var nextindex = state.pageIndex + 1;
      if (nextindex < state.countryData.length) {
        commit("SET_PAGE_INDEX", nextindex);
      }

      var arrows = {
        prev: state.pageIndex > 0 ? true : false,
        next: state.pageIndex < state.countryData.length - 1 ? true : false,
      };
      commit("UPDATE_ARROWS", arrows);

      console.log("[*****************]");
      console.log(
        "[next_page]",
        state.pageIndex,
        "#",
        state.countryData.length
      );
      console.log("[next_page type]", state.pageData.Type, state.pageData);
    },
    download_locales({ state, commit }) {
      locales.forEach((locale) => {
        console.log("fetch:", locale);
      });
    },
  },
  modules: {},
});
