<template>
  <v-container class="full-height">
    <v-row
      :class="slideshowAlignment"
      align="center"
      class="full-height-percent"
    >
      <SlideshowImage
        v-for="(image, index) in pageData.Asset"
        :key="`image-${index}`"
        :data="image"
      />
    </v-row>
  </v-container>
</template>

<script>
//${this.assetsEndpoint}${this.data.Asset.formats.sm.url}
import SlideshowImage from "@/components/SlideshowImage";

import { mapState } from "vuex";
export default {
  components: {
    SlideshowImage,
  },
  computed: {
    ...mapState(["pageData", "slideshowAlignment"]),
  },
};
</script>

<style lang="scss">
.align_center_left {
  justify-content: flex-start !important;
}

.align_center_right {
  justify-content: flex-end !important;
}

.align_center_center {
  justify-content: center !important;
}
</style>
