<template>
  <div class="full-height list-container">
    <RightPanelPicture
      v-for="(image, index) in imageList"
      :key="`image-${index}`"
      :data="image"
    />
  </div>
</template>

<script>
import RightPanelPicture from "@/components/RightPanelPicture";

export default {
  props: {
    imageList: {
      type: Array,
      default: null,
    },
  },
  components: {
    RightPanelPicture,
  },
  mounted() {
    // console.log("imageList", this.imageList);
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: center;
  align-content: flex-start;
  gap: 5%;

  height: auto;
  // width: 100%;
}

.img-cont {
  border: solid 1px green;
  width: 100%;
}

h1 {
  border: 1px solid blue;
  width: 100%;
  height: auto;
}

.list-1 {
  justify-content: flex-start;
  align-content: flex-start;
  height: auto;
}

.list-2 {
  justify-content: flex-start;
  align-content: flex-start;
  height: auto;

  .Default {
    margin-bottom: 11%;
  }
}

.list-3 {
  justify-content: flex-start;
  align-content: space-around;
  height: 100%;
}
</style>
